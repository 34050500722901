/* eslint-disable prettier/prettier */
import axios from 'axios'

let baseURL

if (process.env.NODE_ENV === 'production') {
  baseURL = '/api/v1/web'
} else {
  baseURL = 'http://localhost:3042'
}

export default axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
})
