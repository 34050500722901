import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

// vue3 easy data table
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

// vue3 toastify
import 'vue3-toastify/dist/index.css'

//vue3-multiselect
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

//vue-datepicker
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import VueDayjs from 'vue3-dayjs-plugin'

import setupInterceptors from './services/setupInterceptors'

setupInterceptors(store)

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueDayjs)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('EasyDataTable', Vue3EasyDataTable)
app.component('Multiselect', Multiselect)
app.component('Datepicker', Datepicker)

app.mount('#app')
