<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<script>
import EventBus from './common/EventBus'

export default {
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
  mounted() {
    EventBus.on('logout', () => {
      this.logOut()
    })
  },
  beforeUnmount() {
    EventBus.remove('logout')
  },
}
</script>
